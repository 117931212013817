<template>
    <FilterBrandsModal id="filter-brands-modal" />
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <h1 class="mb-3 text-light">{{ t('bottomBar.browse') }}</h1>
        <!-- <div class="card shadow-sm p-3 mb-4">
            <div class="mb-3">
                <label for="" class="form-label">Name</label>
                <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    placeholder="Pardo 38"
                />
            </div>
            <label for="" class="form-label">Filters</label>
            <div class="d-flex flex-row flex-wrap" style="gap: 0.6rem">
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#filter-brands-modal"
                    href="#"
                    class="badge fs-6 border d-inline-block text-dark bg-light"
                >
                    Brand
                </a>
                <a
                    href="#"
                    class="badge fs-6 border d-inline-block text-dark bg-light"
                >
                    Length
                </a>
                <a
                    href="#"
                    class="badge fs-6 border d-inline-block text-dark bg-light"
                >
                    Cabins
                </a>
                <a
                    href="#"
                    class="badge fs-6 border d-inline-block text-dark bg-light"
                >
                    Passengers
                </a>
            </div>
        </div> -->
        <div class="d-block d-md-none" v-if="yachts">
            <router-link
                v-for="yacht in yachts"
                :key="yacht"
                :to="'/yachts/' + yacht.itself.id"
            >
                <YachtItem width="100%" class="mb-4" :yacht="yacht" />
            </router-link>
        </div>
        <div class="d-none d-md-block" v-if="yachts">
            <div
                style="
                    display: grid;
                    gap: 1rem;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: 1fr 1fr 1fr;
                "
            >
                <div v-for="yacht in yachts" :key="yacht">
                    <router-link :to="'/yachts/' + yacht.itself.id">
                        <YachtItem width="100%" class="mb-4" :yacht="yacht" />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useYachtsStore } from '@/stores/yachts-store';

import YachtItem from '@/components/YachtItem.vue';
import FilterBrandsModal from './Modals/FilterBrandsModal.vue';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const { t } = useI18n();

const yachtsStore = useYachtsStore();
const { yachts } = storeToRefs(yachtsStore);

onMounted(() => {
    yachtsStore.fetchYachts();
});
</script>
